import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import userService from "../services/user.service";

const { vnListadoAgente: viewName, vfVerListadoAgente: viewFunction } =
  viewTypes;
const userName = localStorage.getItem("userName");

// Fetch users
const fetchUser = async (filters) => {
  const response = await userService.ObtenerUsuarios(
    filters,
    userName,
    viewName,
    viewFunction
  );
  return response.data.users;
};

export function useFetchUser(filters) {
  return useQuery({
    queryKey: ["users"],
    queryFn: () => fetchUser(filters),
    retry: false,
    refetchOnWindowFocus: false,
  });
}

// Fetch user by id
const fetchUserById = async (id) => {
  const response = await userService.ObtenerUsuarioById(
    id,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchUserById(id) {
  return useQuery({
    queryKey: ["user", id],
    queryFn: () => fetchUserById(id),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
/** */
