import React, { useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import moment from "moment";
import { viewTypes } from "../common/types/views.type";
import { formTypes } from "../types/form.type";
import { useCampaignStore } from "../store/campaignStore";
import { FormEntrante } from "./slidePanel/forms/FormEntrante";
import { downloadTemplate } from "../services/graph.service";
import { Toast } from "primereact/toast";

export const CampaignPanelContent = ({
  closePanel,
  saveCampaign,
  cambiarCampaign,
  handleVendors,
  submitted,
  setSubmitted,
  retornoUserSelector,
  handleSelectVoicebot,
  userLoading,
}) => {
  const {
    campaign,
    updateCampaignProp,
    selectedVoicebot,
    selectedVendor,
    selectedAsterisks,
    setSelectedAsterisks,
    typeForm,
    errors,
    setErrors,
    validateDefault,
    voicebotCompleto,
    hasVendor,
  } = useCampaignStore((state) => state);

  const {
    addSaliente,
    editSaliente,
    addEntrante,
    editEntrante,
    addSalienteReducido,
  } = formTypes;

  useEffect(() => {
    submitted && validateForm();
  }, [campaign, selectedVoicebot, selectedAsterisks, selectedVendor]);

  const toast = useRef(null);

  let windowTitle = "";
  if (
    typeForm === addSaliente ||
    typeForm === addEntrante ||
    typeForm === addSalienteReducido
  ) {
    windowTitle = "Nueva campaña";
  } else if (typeForm === editSaliente || typeForm === editEntrante) {
    windowTitle = "Editar campaña";
  }

  const { vnListadoAgente: viewName, vfVerListadoAgente: viewFunction } =
    viewTypes;
  const userName = localStorage.getItem("userName");

  const setCampaignAndSave = () => {
    /* limpiar las fechas que no tienen rangos */
    const removeEmptyRangos = (horarios) => {
      let hasRemoved = false;
      const filteredArray = horarios.filter((item) => {
        if (item.rangos.length === 0) {
          hasRemoved = true;
          return false;
        }
        return true;
      });
      if (hasRemoved) {
        toast.current.show({
          severity: "info",
          summary: "info",
          detail:
            "Se eliminaron las fechas que no tienen rangos horarios asociados",
          life: 3000,
        });
      }
      return filteredArray;
    };

    let _horarios = removeEmptyRangos(campaign.horarios);
    updateCampaignProp("horarios", _horarios);

    /* fechaInicio y fin con horas */
    let _fechaInicio = null;
    let _fechaFin = null;

    const obtenerFechas = (horarios) => {
      const primerDia = horarios[0].date;
      const ultimoDia = horarios[horarios.length - 1].date;

      const primerHora = horarios[0].rangos[0].from;
      const ultimaHora =
        horarios[horarios.length - 1].rangos[
          horarios[horarios.length - 1].rangos.length - 1
        ].to;

      _fechaInicio = moment(primerDia).set({
        hour: Number(primerHora.split(":")[0]),
        minute: Number(primerHora.split(":")[1]),
      });

      _fechaFin = moment(ultimoDia).set({
        hour: Number(ultimaHora.split(":")[0]),
        minute: Number(ultimaHora.split(":")[1]),
      });

      _fechaInicio = _fechaInicio.format("YYYY-MM-DDTHH:mm:ss.SSS");
      _fechaFin = _fechaFin.format("YYYY-MM-DDTHH:mm:ss.SSS");
    };

    _horarios.length > 0 && obtenerFechas(_horarios);
    /* */

    const _configurationVendor = hasVendor
      ? {
          id: selectedVendor.id,
          name: selectedVendor.name,
          channel: campaign.canales,
          cps: campaign.cps,
          queueName: selectedVendor.queueName,
          context: selectedVendor.context,
          asterisks: selectedAsterisks,
        }
      : campaign.configuracionVendor;

    const _campaign = {
      id: campaign.id,
      nombre: campaign.name,
      reintentos: campaign.reintentos,
      ejecutarAMD: campaign.activarAMD,
      inPBI: campaign.procesarPBI,
      grabarLlamadas: campaign.grabarLlamadas,
      grabarTransferencia: campaign.grabarTransferencia,
      clientId: campaign.clientId,

      telefono:
        typeForm === addEntrante || typeForm === editEntrante
          ? campaign.telefono
          : null,

      telephoneValidation: campaign.telephoneValidation,
      langTranscript: campaign.langTranscript,
      configuracionTTS: campaign.configuracionTTS,

      configuracionVendor: _configurationVendor,

      idSurvey:
        typeForm === editSaliente || typeForm === editEntrante
          ? campaign.idSurvey
          : selectedVoicebot && selectedVoicebot.surveyId,
      surveyName:
        typeForm === editSaliente || typeForm === editEntrante
          ? campaign.surveyName
          : selectedVoicebot && selectedVoicebot.surveyName,
      surveyTipo:
        typeForm === editSaliente || typeForm === editEntrante
          ? campaign.surveyTipo
          : selectedVoicebot && selectedVoicebot.tipo,

      estados: voicebotCompleto ? voicebotCompleto.estados : null,
      userId: campaign.userId,

      fechaInicio:
        typeForm === addSalienteReducido
          ? moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
          : _fechaInicio,

      fechaFin:
        typeForm === addSalienteReducido
          ? moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
          : _fechaFin,

      fechaCreada:
        typeForm === editSaliente || typeForm === editEntrante
          ? campaign.fechaCreada
          : null,

      horarios: typeForm === addSalienteReducido ? null : _horarios,

      esVisible: campaign.esVisible,

      mostrarEnCreacionCampanna:
        typeForm === editSaliente ||
        (typeForm === editEntrante && campaign.mostrarEnCreacionCampanna)
          ? campaign.mostrarEnCreacionCampanna
          : true,
      useDefault:
        typeForm === editSaliente ||
        (typeForm === editEntrante && campaign.useDefault)
          ? campaign.useDefault
          : false,
      mostrarAudios:
        typeForm === editSaliente ||
        (typeForm === editEntrante && campaign.mostrarAudios)
          ? campaign.mostrarAudios
          : false,

      estado: campaign.estado || null,
      fileContact: campaign.fileContact,

      casosExitosos: campaign.casosExitosos || null,
      casosARealizar: campaign.casosARealizar || null,
      historial: campaign.historial || null,
      comentario: campaign.comentario || null,
    };
    setSubmitted(true);

    if (
      validateForm() &&
      (typeForm === addSaliente || typeForm === addEntrante)
    ) {
      delete _campaign.id;
      delete _campaign.estado;
      delete _campaign.casosARealizar;
      delete _campaign.casosExitosos;
      delete _campaign.historial;

      // console.log(_campaign);
      saveCampaign(_campaign);
    }
    if (
      validateForm() &&
      (typeForm === editSaliente || typeForm === editEntrante)
    ) {
      // console.log(_campaign);
      cambiarCampaign(_campaign);
    }

    if (validateForm() && typeForm === addSalienteReducido) {
      delete _campaign.id;
      delete _campaign.estado;
      delete _campaign.casosARealizar;
      delete _campaign.casosExitosos;
      delete _campaign.historial;

      // console.log(_campaign);
      saveCampaign(_campaign, true);
    }
  };

  const handleAsterisksVendors = (value) => {
    setSelectedAsterisks(value);
  };

  const getTemplate = () => {
    downloadTemplate(
      selectedVoicebot.surveyId,
      selectedVoicebot.surveyName,
      userName,
      viewName,
      viewFunction
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <>
        <div className="panel-title">{windowTitle}</div>
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => setCampaignAndSave()}
            disabled={userLoading}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
            disabled={userLoading}
          />
        </div>
      </>
    );
  };

  const handleVoicebot = (value) => {
    handleSelectVoicebot(value);

    updateCampaignProp("horarios", []);
    updateCampaignProp("fileContact", null);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!campaign.name.trim()) {
      newErrors.name = "El nombre no puede estar vacío";
      valid = false;
    } else {
      newErrors.name = null;
    }

    if (
      campaign.telefono === "" &&
      (typeForm === addEntrante || typeForm === editEntrante)
    ) {
      newErrors.telefono = "Debe agregar un teléfono";
      valid = false;
    } else {
      newErrors.telefono = null;
    }

    if (
      typeForm !== addEntrante &&
      typeForm !== editEntrante &&
      (campaign.reintentos === null ||
        parseInt(campaign.reintentos) < 1 ||
        parseInt(campaign.reintentos) > validateDefault.reintentos ||
        isNaN(parseInt(campaign.reintentos)))
    ) {
      newErrors.reintentos = `Debe ser un número entre 1 y ${validateDefault.reintentos}`;
      valid = false;
    } else {
      newErrors.reintentos = null;
    }

    if (
      (typeForm === addSaliente ||
        typeForm === addEntrante ||
        typeForm === addSalienteReducido) &&
      (selectedVoicebot === null || selectedVoicebot === undefined)
    ) {
      newErrors.selectedVoicebot = "Debe seleccionar un voicebot";
      valid = false;
    } else {
      newErrors.selectedVoicebot = null;
    }

    if (
      campaign.canales === null ||
      parseInt(campaign.canales) < 1 ||
      parseInt(campaign.canales) > validateDefault.canales ||
      isNaN(parseInt(campaign.canales))
    ) {
      newErrors.canales = `Debe ser un número entre 1 y ${validateDefault.canales}`;
      valid = false;
    } else {
      newErrors.canales = null;
    }

    if (
      campaign.cps === null ||
      parseInt(campaign.cps) < 1 ||
      parseInt(campaign.cps) > validateDefault.cps ||
      isNaN(parseInt(campaign.cps))
    ) {
      newErrors.cps = `Debe ser un número entre 1 y ${validateDefault.cps}`;
      valid = false;
    } else {
      newErrors.cps = null;
    }

    if (!selectedAsterisks || selectedAsterisks.length === 0) {
      newErrors.selectedAsterisks = "Debe seleccionar al menos uno";
      valid = false;
    } else {
      newErrors.selectedAsterisks = null;
    }

    if (
      (typeForm !== addEntrante &&
        typeForm !== editEntrante &&
        !campaign.fileContact) ||
      (campaign.fileContact &&
        !(campaign.fileContact.file || campaign.fileContact.idPathContactos))
    ) {
      newErrors.file =
        typeForm === addSaliente
          ? "Debe importar o seleccionar un archivo de contactos"
          : "Debe importar un archivo de contactos";
      valid = false;
    } else {
      newErrors.file = null;
    }

    if (
      typeForm !== addSalienteReducido &&
      campaign.horarios &&
      campaign.horarios.length === 0
    ) {
      newErrors.horarios =
        "Debe agregar al menos una fecha con rangos horarios";
      valid = false;
    } else {
      newErrors.horarios = null;
    }

    setErrors(newErrors);
    return valid;
  };

  return (
    <div className="slide-panel">
      <Toast ref={toast} />

      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {(typeForm === addEntrante || typeForm === editEntrante) && (
        <FormEntrante
          submitted={submitted}
          retornoUserSelector={retornoUserSelector}
          handleVoicebot={handleVoicebot}
          getTemplate={getTemplate}
        />
      )}
    </div>
  );
};
