import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { FilterWrapper } from "./_filterPanel/FilterWrapper";
import {
  estadosUtils,
  estadosUtilsCompleto,
  styleCombo,
} from "../utils/table.uitls";
import { MultiSelect } from "primereact/multiselect";
import { ShowView } from "../common/components/ShowView";
import { viewTypes } from "../common/types/views.type";
import { useCampaignStore } from "../store/campaignStore";

export const FilterPanel = ({ lazyParams, setLazyParams, initParams }) => {
  const { voicebotsCombo } = useCampaignStore((state) => state);
  /** */
  const { vnGeneral, vfEstadoDeCampanas } = viewTypes;
  const viewEstado = ShowView(vfEstadoDeCampanas, vnGeneral);
  const tipoEstado = viewEstado ? estadosUtilsCompleto : estadosUtils;

  const statusBodyTemplateSelect = (data) => {
    return (
      <span
        className={
          "customer-badge status-" + styleCombo(data.name) + " text-status"
        }
      >
        {data.name}
      </span>
    );
  };

  const selectedStatesTemplate = (option) => {
    if (option) {
      return (
        <div
          className={
            "customer-badge status-" + styleCombo(option) + " text-status small"
          }
          style={{ display: "inlineBlock" }}
        >
          <div>{option}</div>
        </div>
      );
    } else {
      return "Selecciona los estados";
    }
  };

  const initFilters = {
    surveys: "",
    nombre: "",
    fechas: "",
    estado: "",
  };

  const [filters, setFilters] = useState(initFilters);

  const filtros = [
    {
      title: "Agente",
      filtro: "surveys",
      input: (
        <div
          className="select-container"
          style={{ justifyContent: "flex-start" }}
        >
          <MultiSelect
            value={filters.surveys}
            options={voicebotsCombo}
            onChange={(e) => handleChange("surveys", e.value)}
            optionLabel="surveyName"
            filterBy="surveyName"
            placeholder="Elige los agentes"
          />
        </div>
      ),
    },
    {
      title: "Nombre",
      filtro: "nombre",
      input: (
        <InputText
          className="filter-text"
          value={filters.nombre}
          placeholder="Ingresa el nombre"
        />
      ),
    },
    {
      title: "Estado",
      filtro: "estado",
      input: (
        <div className="select-container">
          <MultiSelect
            value={filters.estado}
            options={tipoEstado}
            onChange={(e) => handleChange("estado", e.value)}
            optionLabel="name"
            filterBy="name"
            placeholder="Selecciona los estados"
            itemTemplate={statusBodyTemplateSelect}
            selectedItemTemplate={selectedStatesTemplate}
          />
        </div>
      ),
    },
  ];

  const handleChange = (name, value) => {
    setFilters((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
    setLazyParams((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
  };

  /** */
  return (
    <FilterWrapper
      lazyParams={lazyParams}
      setLazyParams={setLazyParams}
      initFilters={initFilters}
      filtros={filtros}
      initParams={initParams}
      filters={filters}
      setFilters={setFilters}
      handleChange={handleChange}
    />
  );
};
